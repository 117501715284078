<template>
  <div class="blog">
    <div class="main overflow-hidden">
      <HeaderSection />
      <BreadcrumbSection :titulo="blogData.titulo" :descripcion="blogData.descripcion" :background="blogData.image"/>
      <section class="section blog-area ptb_100">
        <div class="container">
          <h4> Contenido   </h4>
          <p>
            {{ blogData.contenido }}
          </p>
          <hr>
          <div v-if="blogData.pdf">
            <h4>{{currentPage}} / {{pageCount}}</h4>
            <pdf :src="blogData.pdf"
                 @num-pages="pageCount = $event"
                 @page-loaded="currentPage = $event"
                 v-for="i in numPages"
                 :key="i"
                 :page="i"
            ></pdf>
          </div>
        </div>
      </section>
      <FooterSection />
      <ModalSearchSection />
      <ModalRegistro />
      <ModalMenuSection />
      <ModalPopUp />
    </div>
  </div>
</template>

<script>
import HeaderSection from "../../Header/HeaderOne";
import BreadcrumbSection from "../../Breadcrumb/BlogThreeColumn";
import FooterSection from "../../Footer/FooterThree";
import ModalSearchSection from "../../Modal/ModalSearch/ModalSearch";
import ModalMenuSection from "../../Modal/ModalMenu/ModalMenu";
import {fr,analytics} from "@/firebase";
import pdf from 'vue-pdf';
import ModalPopUp from "../../Modal/ModalAds/EmailPopUp"


export default {
  name: "BlogDescription",
  components: {
    HeaderSection,
    BreadcrumbSection,
    FooterSection,
    ModalSearchSection,
    ModalMenuSection,
    ModalRegistro: () => import("../../Modal/ModalSearch/ModalRegistro"),
    pdf,
    ModalPopUp,
  },
  beforeDestroy() {
    sessionStorage.removeItem("currentBlog")
  },
  beforeMount() {
    if( sessionStorage.getItem('currentBlog') != null ){
      this.idPost = sessionStorage.getItem('currentBlog');
    } else {
      this.$router.push({name: "blog"});
    }
  },
  mounted() {
    this.getInfoPost();
    this.EmailPopUpModal();
  },
  metaInfo: {
    title: "Electoral Tech & Law | Blog ",
    meta: [
      {
        name: "description",
        content: "Post con contenido relevante en material electoral",
      },
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        property: "og:title",
        content:
          "ElectoralTech Blog, un sitio donde encontraras artículos relacinados con derecho electoral, temas juridicos relevantes",
        vmid: "og:title",
      },
      {
        name: "keywords",
        content: "Electroal, Ley, Law, Formatos, Blog, Veracruz",
      },
      {
        name: "author",
        content: "Dr. José Olíveros ",
      },
    ],
  },
  data() {
    return {
      idPost:"",
      blogData: {},
      numPages: 0,
      currentPage: 0,
      pageCount: 0,
    };
  },
  methods: {
    async getInfoPost(){
      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      var dateTime = date+' '+time;
      if ( this.idPost != "") {
        const snap = await fr.collection('blog').doc(this.idPost).get();
        
        if ( snap.exists ){
          this.blogData = snap.data();
          const loadingTask  = pdf.createLoadingTask( this.blogData.pdf );
          loadingTask.promise.then( pdf => {
            this.numPages = pdf.numPages; 
          })
        }
        analytics.logEvent("Blog-Post",{
          blog_time: dateTime,
          event_time: dateTime,
          event_title: this.blogData.titulo,
          event_label: dateTime,
          event_category: this.blogData.titulo
        });
        await  this.EmailAlwaysPopUpModal();
      }
    },
    EmailPopUpModal(){
      const registered = window.sessionStorage.getItem("usuario");
      if(registered == null){
        const validation = window.sessionStorage.getItem("EmailPopUp");
        if(validation == null){
          window.$("#EmailPopUp").modal("show");
        }
        window.sessionStorage.setItem("EmailPopUp",true);
        console.log("El SESSION STORAGE:", validation);
      }      
    },
    EmailAlwaysPopUpModal(){  
      setTimeout(() => { this.ShowModal()}, 12000);
    },
    ShowModal(){
      window.$("#EmailPopUp").modal("show")
    }
  },
  
};
</script>

<style>
</style>